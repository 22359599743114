import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import { useStore } from '../../store/hooks';
import './UsersManagement.scss';
import Button from '../../components/Button/Button';
import { checkSinglePermission } from '../../security/security-hook';
import { PERMISSION_ALLOW, RESOURCE_USER_MANAGEMENT_ADD_USER } from '../../types/users-types';
import { isDefined } from '../../utils/object-utils';
import SearchBar from '../../components/SearchBar/SearchBar';
import { runInAction } from 'mobx';
import Checkbox from '../../components/Checkbox/Checkbox';

const UsersManagement = observer(() => {
  const { usersManagementStore: store, globalStore } = useStore();
  const { colDefs, filteredUsers, loaders, hideGuests, searchText, sorting } = store;

  useEffect(() => {
    store.init();
  }, [store]);

  if (loaders.page) return <Spinner />;

  const addUserBtnIsEnabled = checkSinglePermission(globalStore.root.apiStore.userGlobalPermissions, RESOURCE_USER_MANAGEMENT_ADD_USER, PERMISSION_ALLOW);

  return (
    <div className='users-management-wrapper'>
      <div className='page-heading skeleton'>
        <div className='heading--level-1'>Users</div>
      </div>

      <div className='user-management_filter'>
        <div>
          <Button
            className="add-more-btn"
            ariaLabel="add-more"
            type="link"
            size="small"
            icon="plus"
            disabled={ loaders.userAction || store.currentUserIndex !== undefined || !addUserBtnIsEnabled }
            onClick={ () => store.addUsersModal() }
          >
            Add Users
          </Button>
          {loaders.userAction && <Spinner size={24} />}
          <div className="search-bar-wrapper">
            <SearchBar
              className='skeleton'
              ariaLabel='label-search'
              disabled={loaders.userAction}
              maxLength={15}
              initialValue={searchText}
              placeholder='Search User Email, First Name, Last Name'
              onSearch={v => runInAction(() => store.searchText = v)}
            />
          </div>
          <Checkbox
            id='hide-guests'
            ariaLabel='hide-guests'
            className='hide-guests skeleton item-editors'
            small
            disabled={loaders.userAction || store.currentUserIndex !== undefined}
            checked={hideGuests}
            onChange={v => runInAction(() => store.hideGuests = v)}
          >
            Hide Guests
          </Checkbox>
        </div>
      </div>
      <Grid
        id='users-management-grid'
        columns={colDefs}
        data={filteredUsers}
        sorting={sorting}
      />
      <div className="user-management_actions">
        <Button className="back item-editors" type='link' size='small' onClick={() => store.back()}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' disabled={loaders.userAction || isDefined(store.currentUserIndex)} onClick={() => store.done()}>Dashboard</Button>
        </div>
      </div>
    </div>
  );
});

export default UsersManagement;
