import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import { User } from '../../types/practice-types';
import { runInAction } from 'mobx';
import TextBox from '../../components/TextBox/TextBox';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import React from 'react';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';
import Button from '../../components/Button/Button';

interface CommonRendererProps {
  row: User;
  index: number;
}

interface RowCellRendererProps extends CommonRendererProps {
  field: keyof User;
}

const AddUsersRowCellRenderer = observer((props: RowCellRendererProps) => {
  const { row, index, field } = props;
  const store = useStore().addUsersStore;
  const { loaders } = store;
  const changeValue = (field: keyof User, value: string): void => {
    if (field === 'roles' || (field === 'phone' && !/^\d*$/.test(value))) return;
    runInAction(() => {
      row[field] = value;
      store.validated = false;
      store.userCreateObjects[index].errors[field] = undefined;
    });
  };

  if (field !== 'roles') {
    return (
      <TextBox
        ariaLabel={`table-cell-text-box_${field}`}
        placeholder={field === 'phone' ? 'Add Phone' : field === 'email' ? 'MyIDEXX email' : ''}
        inCell
        value={row[field] || ''}
        maxLength={field === 'phone' ? 10 : undefined}
        onChange={v => changeValue(field, v)}
        focus={field === 'firstName' || index === 0}
        error={store.userCreateObjects[index].errors[field]}
        disabled={loaders.validateUsers}
      />
    );
  }
  if (field === 'roles') {
    return (
      <CheckboxGroup
        id="practice-roles-group"
        groupClass="item-editors"
        type="horizontal"
        small
        values={store.roles}
        displayField="description"
        onChange={indices => store.changeRoleIndices(index, indices)}
        checkedIndices={store.userCreateObjects[index].roleIndices}
        allDisabled={loaders.validateUsers}
      />
    );
  }
  return null;
});

const AddUsersActionsRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  const store = useStore().addUsersStore;
  return (
    <SpotIconButton
      path="delete"
      onClick={() => store.deleteAddUsersRow(row)}
      title="Delete user"
      cypressData={`delete-add-user-${row.email}-btn`}
    />
  );
});

export const ModalCustomFooter = observer(() => {
  const store = useStore().addUsersStore;
  const { userCreateObjects, loaders, usersValid } = store;

  return <>
    <Button
      type="secondary"
      onClick={() => store.addUsersRow()}
      disabled={loaders.createUsers || loaders.validateUsers}
    >
      Add Row
    </Button>
    <Button
      type="secondary"
      loading={loaders.validateUsers}
      disabled={userCreateObjects.length === 0 || loaders.createUsers}
      onClick={() => store.validateAddUsers()}
    >
      Validate
    </Button>
    <Button type="primary" loading={loaders.createUsers} disabled={!usersValid || loaders.validateUsers} onClick={() => store.createUsers()}>
      Create User(s)
    </Button>
  </>;
});


export const addUsersRowCellRenderer = (field: keyof User) => {
  const rowCellRendererWrapper = (row: User, index: number) => <AddUsersRowCellRenderer row={row} index={index} field={field} />;
  return rowCellRendererWrapper;
};

export const addUsersActionsRenderer = (row: User, index: any) => <AddUsersActionsRenderer row={row} index={index} />;
