export const semverLessThan = (v1: string | undefined, v2: string | undefined, orEqual = true): boolean => {
  if (!v1 || !v2) return false;
  const array1 = v1.replace(/[^\d.]/g, '').split('.').filter(v => v).map(v => parseInt(v));
  const array2 = v2.replace(/[^\d.]/g, '').split('.').filter(v => v).map(v => parseInt(v));
  if (array1.length === 0 || array2.length === 0) return false;
  for (let i = 0; i < Math.min(array1.length, array2.length); i++) {
    if (array1[i] < array2[i]) return true;
    if ((array1[i] > array2[i])) return false;
  }
  if (array1.length === array2.length) return orEqual;
  return array1.length < array2.length;
};
