import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import './Popover.scss';

const POSITION_CLASSES = {
  top: 'spot-popover--top-center',
  left: 'spot-popover--center-left',
  right: 'spot-popover--center-right',
  bottom: 'spot-popover--bottom-center',
  'top-left': 'spot-popover--top-left',
  'top-right': 'spot-popover--top-right',
  'bottom-left': 'spot-popover--bottom-left',
  'bottom-right': 'spot-popover--bottom-right',
};

const ALIGN_CLASSES = {
  'top-left': 'spot-popover--align-top-left',
  'top-right': 'spot-popover--align-top-right',
  'bottom-left': 'spot-popover--align-bottom-left',
  'bottom-right': 'spot-popover--align-bottom-right',
};

const INSET_CLASSES = {
  no: 'spot-popover--no-inset',
  small: 'spot-popover--small',
  medium: '',
  large: 'spot-popover--large',
};

const SPOT_POPOVER_SHOW_CLASS = 'spot-popover--shown';

interface PopoverProps extends CustomElementProps {
  id: string;
  position: keyof typeof POSITION_CLASSES;
  align?: keyof typeof ALIGN_CLASSES;
  inset?: keyof typeof INSET_CLASSES;
  element: ReactElement;
  shown?: boolean;
  setShown?: (v: boolean) => void;
  cypressData?: string;
}

const Popover = (props: PopoverProps) => {
  const { id, position, children, className, element, align, inset, setShown, shown, cypressData } = props;
  const popoverRef = useRef<any>();
  const [innerShown, setInnerShown] = useState<boolean>(false);

  const windowClickListener = useCallback((evt: any) => {
    if (!evt.target.closest(`#${id}`)) {
      setShown ? setShown(false) : setInnerShown(false);
    }
  }, [id, setShown]);

  useEffect(() => {
    if (setShown ? shown : innerShown) {
      popoverRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      window.addEventListener('click', windowClickListener);
    } else {
      window.removeEventListener('click', windowClickListener);
    }
    return () => {
      window.removeEventListener('click', windowClickListener);
    };
  }, [id, innerShown, setShown, shown, windowClickListener]);

  const computedClass = [
    'spot-popover',
    POSITION_CLASSES[position],
    align && ALIGN_CLASSES[align],
    setShown ? shown && SPOT_POPOVER_SHOW_CLASS : innerShown && SPOT_POPOVER_SHOW_CLASS,
    inset && INSET_CLASSES[inset],
    className,
  ].join(' ');

  const onClick = () => {
    setShown ? setShown(!shown) : setInnerShown(!innerShown);
  };

  return (
    <span className="spot-popover-wrapper">
      <div spot-popover-id={id} onClick={onClick} data-cy={cypressData}>
        {element}
      </div>
      <div ref={popoverRef} className={computedClass} id={id}>{children}</div>
    </span>
  );
};

export default Popover;
