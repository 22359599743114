import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import Popover from '../../components/Popover/Popover';
import SpotIcon from '../../components/SpotIcon/SpotIcon';
import Button from '../../components/Button/Button';
import TextBox from '../../components/TextBox/TextBox';
import { CsVersion } from '../../types/practice-types';
import { runInAction } from 'mobx';

interface IndexProps {
  index: number;
}

interface EditFieldRendererProps extends IndexProps {
  field: keyof CsVersion;
  numeric?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

const EditFieldRenderer = observer((props: EditFieldRendererProps) => {
  const { index, numeric, field, disabled, maxLength } = props;
  const store = useStore().upgradeVersionManagementStore;
  const { versions, currentVersion, selectedVersionIndex, editMode, validation } = store;
  const csVersion = (editMode && selectedVersionIndex === index) ? currentVersion : versions[index];

  const changeValue = (newValue: string): void => {
    if (numeric && !/^\d+/.test(newValue)) return;
    runInAction(() => {
      // @ts-ignore
      csVersion[field] = numeric ? parseInt(newValue) : newValue;
    });
  };

  return editMode && selectedVersionIndex === index ? (
    <TextBox
      ariaLabel={`edit-text-box_${field}`}
      value={String(csVersion[field])}
      maxLength={maxLength}
      onChange={v => changeValue(v)}
      error={validation[field]}
      disabled={disabled}
      inCell
    />
  ) : (
    <strong>{csVersion[field]}</strong>
  );
});

export const UpgradeVersionContent = observer((props: IndexProps) => {
  const { index } = props;
  const store = useStore().upgradeVersionManagementStore;
  const { isNewVersion } = store;

  return <>
    <ul>
      <li>Version Number: <EditFieldRenderer index={index} field='version' maxLength={20} disabled={!isNewVersion} /></li>
      <li>Installer Name: <EditFieldRenderer index={index} field='installerName' maxLength={30} /></li>
      <li>Installer Size (GB): <EditFieldRenderer index={index} field='installerSize' maxLength={10} numeric /></li>
      <li>Installer Arguments: <EditFieldRenderer index={index} field='installerArgs' maxLength={30} /></li>
    </ul>
    <ul>
      <li>Minimum Supported Version: <EditFieldRenderer index={index} field='minimalUpgradeVersion' maxLength={20} /></li>
      <li>Supported Operating Systems: <EditFieldRenderer index={index} field='osPattern' maxLength={30} /></li>
      <li>Supported CPU Families: <EditFieldRenderer index={index} field='cpuPattern' maxLength={30} /></li>
      <li>Kaseya Qualification Procedure: <EditFieldRenderer index={index} field='qualificationAPs' maxLength={50} /></li>
    </ul>
    <ul>
      <li>Server RAM (GB): <EditFieldRenderer index={index} field='serverRam' maxLength={10} numeric /></li>
      <li>Server Disk Capacity (GB): <EditFieldRenderer index={index} field='serverDiskSize' maxLength={10} numeric /></li>
      <li>Workstation RAM (GB): <EditFieldRenderer index={index} field='workstationRam' maxLength={10} numeric /></li>
      <li>Workstation Disk Capacity (GB): <EditFieldRenderer index={index} field='workstationDiskSize' maxLength={10} numeric /></li>
    </ul>
  </>;
});

export const PopoverRenderer = observer((props: IndexProps) => {
  const { index } = props;
  const store = useStore().upgradeVersionManagementStore;
  const { loaders, versions } = store;
  const item = versions[index];

  const [popoverShown, setPopoverShown] = useState<boolean>(false);

  return (
    <div className="actions-popover">
      <Popover
        id={`actions-popover-${index}`}
        position="bottom-left"
        inset="small"
        element={<SpotIcon ariaLabel="agent-ellipsis" path="more-2" title="Click the action menu to access version specific options."/>}
        shown={popoverShown}
        setShown={setPopoverShown}
        cypressData={`actions-${index}-popover`}
      >
        <Button
          type="link"
          size="small"
          disabled={loaders.save}
          onClick={() => { void store.startEdit(index); setPopoverShown(false);}}
          cypressData={`edit-${index}-link`}
        >
          Edit
        </Button>
        <Button
          type="link"
          size="small"
          disabled={loaders.save}
          onClick={() => { void store.cloneAndEdit(index); setPopoverShown(false);}}
          cypressData={`clone-${index}-link`}
        >
          Clone
        </Button>
        <Button
          type="link"
          size="small"
          disabled={loaders.save || item.default}
          onClick={() => { void store.makeDefault(index); setPopoverShown(false);}}
          cypressData={`make-default-${index}-link`}
        >
          Make Default
        </Button>
        <Button
          type="link"
          size="small"
          disabled={loaders.save || !item.isDeletable || item.default}
          onClick={() => { void store.showDeleteModal(index); setPopoverShown(false);}}
          cypressData={`delete-${index}-link`}
        >
          Delete
        </Button>
      </Popover>
    </div>
  );
});

