import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Button from '../../components/Button/Button';
import Grid from '../../components/Grid/Grid';
import { useStore } from '../../store/hooks';

const AddUsersContent = observer(() => {
  const { addUsersStore: store, globalStore } = useStore();
  const { addUsersColDef, userCreateObjects } = store;
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    store.init();
  }, [globalStore.globalModalProps, store]);

  const handleClickUpload = () => {
    if (fileInput?.current) {
      fileInput.current.click();
    }
  };

  const onFileChange = (event: any) => {
    void store.processUsersFile(event.target.files[0]);
    event.target.value = '';
  };

  return (
    <div className="add-users">
      <Button type='primary' size='small' ariaLabel='upload-csv' onClick={handleClickUpload}>Upload CSV file</Button>
      <span>File should contain the following column headers:
        <strong> firstName</strong>,
        <strong> lastName</strong>,
        <strong> email</strong>,
        <strong> phone</strong> (optional),
        <strong> roles</strong> (semicolon separated role names)
      </span>
      <input type="file" accept="text/csv" ref={fileInput} role="file-input" onChange={onFileChange} />
      <Grid
        id="user-management-add-users-grid"
        columns={addUsersColDef}
        data={userCreateObjects.map(o => o.user)}
      />
    </div>
  );
});

export default AddUsersContent;
