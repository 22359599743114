import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import './UpgradeVersionManagement.scss';
import Spinner from '../../components/Spinner/Spinner';
import { useStore } from '../../store/hooks';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import SpotIcon from '../../components/SpotIcon/SpotIcon';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { PopoverRenderer, UpgradeVersionContent } from './renderers';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';

const UpgradeVersionManagement = observer(() => {
  const store = useStore().upgradeVersionManagementStore;
  const { loaders, selectedVersionIndex, editMode, spinnerVersionIndex } = store;
  const navigate = useNavigate();

  useEffect(() => {
    void store.init();
    return () => store.close();
  }, [store]);

  const focusRef = useCallback((el: HTMLDivElement | null, index) => {
    if (index === selectedVersionIndex) el?.scrollIntoView({ block: 'center' });
  }, [selectedVersionIndex]);

  const contentHeaderClick = useCallback((index: number, e: any) => {
    if (!e.target.closest('.spot-popover-wrapper')) store.changeSelectedVersion(index);
  }, [store]);

  if (loaders.page) return <Spinner />;

  return (
    <div className='upgrade-version-management_wrapper'>
      <div className='page-heading'>
        <div className='heading--level-1'>Upgrade Version Management</div>
        <div className='spot-typography__text--tertiary'>
          Manage which versions of Cornerstone can be upgraded to using the upgrade service and view details for each version.
        </div>
      </div>
      <div className='upgrade-version_top-bar'>
        <Button
          className='add-version-btn'
          ariaLabel='add-version'
          type='link'
          size='small'
          icon='plus'
          disabled={loaders.save || editMode}
          onClick={() => store.addVersion()}
        >
          Add version
        </Button>
      </div>
      {store.versions.map((version, index) => (
        <div
          className='version-header'
          key={index}
          ref={el => focusRef(el, index)}
        >
          <ContentHeader
            id={`expand-toggle-${index}`}
            className='expand-toggle'
            onClick={(e: any) => contentHeaderClick(index, e)}
            cypressData={`expandable-${version.version}`}
          >
            <div className='left-align'>
              {selectedVersionIndex === index
                ? <SpotIcon className='caret-icon' path='caret-up' title='collapse' />
                : <SpotIcon className='caret-icon' path='caret-down' title='expand' />
              }
              &nbsp;{version.version}{version.default ? ' (Default)' : ''}&nbsp;
              {loaders.save && spinnerVersionIndex === index && <Spinner size={24} />}
            </div>
            <div className='right-align'>
              {editMode ? selectedVersionIndex === index && <>
                <SpotIconButton
                  path='save'
                  onClick={() => store.saveVersion()}
                  disabled={loaders.save}
                  title='Save changes'
                  cypressData={`save-${index}-btn`}
                />
                <SpotIconButton
                  path='cancel'
                  disabled={loaders.save}
                  onClick={() => store.cancelEditing()}
                  title='Cancel changes'
                  cypressData={`cancel-${index}-btn`}
                />
              </> : (
                <PopoverRenderer index={index} />
              )}
            </div>
          </ContentHeader>
          <div className={`version-content ${selectedVersionIndex !== index ? 'collapsed' : ''}`}>
            <UpgradeVersionContent index={index} />
          </div>
        </div>
      ))}
      <div className="upgrade-version_actions">
        <Button className="back" type='link' size='small' onClick={() => navigate(-1)}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' onClick={() => navigate('/')}>Dashboard</Button>
        </div>
      </div>
    </div>
  );
});

export default UpgradeVersionManagement;
