import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import { runInAction } from 'mobx';
import './SideMenu.scss';

const SideMenu = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { globalStore, sideMenuStore: store } = useStore();
  const { currentPath, currentPathItem, pathsToShow } = store;

  useEffect(() => {
    runInAction(() => store.currentPath = location.pathname);
  }, [location.pathname, store]);

  if (pathsToShow.length === 0) {
    return <>&nbsp;</>; // this is required to fill in a menu panel
  }

  const doNavigate = (e: any, path: string, enabled: boolean): void => {
    if (enabled && path !== currentPath) navigate(path);
    e.preventDefault();
  };

  return (
    <div className='side-menu skeleton'>
      <nav>
        {pathsToShow.map(path => {
          const menuItemEnabled = !path.enabled || path.enabled(globalStore);
          const className = [
            'menu-nav-link',
            path.route === currentPathItem.route ? 'active' : '',
            path.isSection ? 'subheader' : '',
            menuItemEnabled ? '' : 'not-allowed',
          ].join(' ');
          const route = path.route;
          if (route) {
            return (
              <div key={route} className={className}>
                <a
                  role='side-menu-item'
                  aria-label={path.name}
                  data-cy={path.name}
                  onClick={e => doNavigate(e, route, menuItemEnabled)}
                >
                  {path.item || path.name}
                </a>
              </div>
            );
          } else {
            return (
              <div key={path.name} className={className}>
                {path.item || path.name}
              </div>
            );
          }
        })}
      </nav>
      <div className='empty-space' />
    </div>
  );
});

export default SideMenu;
