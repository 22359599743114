import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import type { Role, User } from '../../types/practice-types';
import { emptyUser } from './UsersManagementStore';
import { GridColDef } from '../../components/Grid/Grid';
import { parseCsvFile } from '../../utils/csv-utils';
import { addUsersActionsRenderer, addUsersRowCellRenderer, ModalCustomFooter } from './addUsersRenderers';
import { USERS_PATH } from '../../store/constants';
import React from 'react';

interface Loaders {
  addUsers?: boolean;
  validateUsers?: boolean;
  createUsers?: boolean;
}

interface UserEditObject {
  user: User;
  errors: Partial<Record<keyof User, string>>,
  roleIndices: number[];
}

const USER_FIELDS: (keyof User)[] = ['firstName', 'lastName', 'email', 'phone', 'roles'];

export default class AddUsersStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable userCreateObjects: UserEditObject[] = [];

  @observable validated = false;

  public get addUsersColDef(): GridColDef[] {
    return [
      { headerName: 'First Name', field: 'firstName', renderer: addUsersRowCellRenderer('firstName') },
      { headerName: 'Last Name', field: 'lastName', renderer: addUsersRowCellRenderer('lastName') },
      { headerName: 'Email', field: 'email', renderer: addUsersRowCellRenderer('email') },
      { headerName: 'Phone (mobile)', field: 'phone', renderer: addUsersRowCellRenderer('phone') },
      { headerName: 'Roles', field: 'roles', renderer: addUsersRowCellRenderer('roles') },
      { headerName: 'Actions', field: 'actions', renderer: addUsersActionsRenderer },
    ];
  }

  @computed
  public get roles(): Role[] {
    return this.root.usersManagementStore.roles;
  }

  @computed
  public get usersValid(): boolean {
    if (!this.validated || this.userCreateObjects.length === 0) return false;
    for (const userEditObject of this.userCreateObjects) {
      if (Object.keys(userEditObject.errors).length > 0) return false;
    }
    return true;
  }

  @action
  public init(): void {
    this.root.globalStore.globalModalProps.customFooter = React.createElement(ModalCustomFooter);
    this.root.globalStore.globalModalProps.wide = true;
    this.loaders = {};
    this.userCreateObjects = [];
    this.addUsersRow();
    this.validated = false;
  }

  @action
  public async processUsersFile(file: File): Promise<void> {
    runInAction(() => this.loaders.addUsers = true);
    const userCreateObjects: UserEditObject[] = [];
    try {
      const users = await parseCsvFile<User>(file, USER_FIELDS);
      users.forEach(user => {
        Object.keys(user).forEach(key => {
          const fieldName = key as keyof User;
          // @ts-ignore
          user[fieldName] = String(user[fieldName] || '').trim();
        });
        const roleDescriptions = (user.roles as unknown as string || '').split(';').map(r => r.trim());
        const userEditObject: UserEditObject = {
          user,
          errors: {},
          roleIndices: [],
        };
        const roles: Role[] = [];
        roleDescriptions.forEach(roleDescription => {
          const index = this.roles.findIndex(role => role.description === roleDescription);
          if (index > -1) {
            userEditObject.roleIndices.push(index);
            roles.push(this.roles[index]);
          }
        });
        user.roles = roles;
        userCreateObjects.push(userEditObject);
      });
      runInAction(() => {
        this.userCreateObjects = userCreateObjects;
        this.validated = false;
      });
    } catch (e: any) {
      alert(`Error parsing CSV file`);
    }
    runInAction(() => this.loaders.addUsers = false);
  }

  @action
  public addUsersRow(): void {
    this.userCreateObjects.push({
      user: emptyUser(),
      errors: {},
      roleIndices: [],
    });
    this.validated = false;
  }

  @action
  public deleteAddUsersRow(row: any): void {
    const index = this.userCreateObjects.map(o => o.user).findIndex(r => r === row);
    this.userCreateObjects.splice(index, 1);
  }

  @action
  public changeRoleIndices(userIndex: number, roleIndices: number[]): void {
    this.userCreateObjects[userIndex].roleIndices = roleIndices;
    this.userCreateObjects[userIndex].user.roles = roleIndices.map(i => this.roles[i]);
    this.validated = false;
  }

  @action
  public async validateAddUsers(): Promise<void> {
    runInAction(() => this.loaders.validateUsers = true);
    try {
      const errors = await this.root.apiStore.backendPost<Partial<Record<keyof User, string>>>(USERS_PATH, {
        action: 'VALIDATE_MULTIPLE',
        users: this.toBackendUsersPayload(),
      });
      runInAction(() => {
        this.userCreateObjects.forEach((obj, index) => {
          obj.errors = errors[index];
        });
        this.validated = true;
      });
    } catch (e: any) {
      alert(`Validation error: ${e.message}`);
    }
    runInAction(() => {
      this.loaders.validateUsers = false;
    });
  }

  @action
  public async createUsers(): Promise<void> {
    runInAction(() => {
      this.loaders.createUsers = true;
      this.root.globalStore.globalModalProps.okDisabled = true;
    });
    try {
      await this.root.apiStore.backendPost(USERS_PATH, {
        action: 'CREATE_MULTIPLE',
        users: this.toBackendUsersPayload(),
      });
      runInAction(() => {
        this.loaders.createUsers = false;
        this.root.globalStore.globalModalProps.show = false;
        this.root.usersManagementStore.fetchUsers();
      });
    } catch (e: any) {
      alert(`User creation error: ${e.message}`);
    }
    runInAction(() => {
      this.loaders.createUsers = false;
    });
  }

  private toBackendUsersPayload(): any {
    return this.userCreateObjects.map(obj => ({
      ...obj.user,
      roleNames: obj.user.roles.map(r => r.name),
      roles: undefined,
    }));
  }
}
