import AbstractStore from '../../store/AbstractStore';
import { computed, observable } from 'mobx';
import paths, { getPathPermissions, getPathsForCategory } from '../../paths';
import type { PathType } from '../../paths';
import { ResourcePermission } from '../../types/users-types';
import { checkIfAnyPermissionAllowed } from '../../security/security-hook';

export default class SideMenuStore extends AbstractStore {
  @observable currentPath = '';

  @computed
  public get currentPathItem(): PathType {
    return paths.find(p => p.route === this.currentPath) || paths[0];
  }

  @computed
  public get pathsToShow(): PathType[] {
    return getPathsForCategory(this.currentPathItem.category).filter(path => {
      if (path.display && !path.display(this.root.globalStore)) return false;
      if (!checkIfAnyPermissionAllowed(this.root.apiStore.userPermissions, getPathPermissions(path, this.currentPathItem))) return false;
      return !path.showWithFeatureFlag || this.root.apiStore.user?.featureFlags?.[path.showWithFeatureFlag];
    });
  }

  @computed
  public get allPathsPermissions(): ResourcePermission[] {
    return ([] as ResourcePermission[]).concat(...paths.map(path => getPathPermissions(path, this.currentPathItem)));
  }
}
