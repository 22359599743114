import Papa, { ParseResult } from 'papaparse';

export const parseCsvFile = async <T extends Record<string, any>>(file: File, validFields?: string[]): Promise<T[]> => {
  const parsed = await new Promise<ParseResult<T>>((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ParseResult<any>) => resolve(results),
      error: (error: Error) => reject(error),
    });
  });
  if (parsed.errors.length > 0) {
    throw new Error(`Parsing errors: ${parsed.errors.join('; ')}`);
  }
  if (validFields) {
    parsed.data.forEach(row => {
      Object.keys(row).forEach(field => {
        if (!validFields.includes(field)) delete row[field];
      });
    });
  }
  return parsed.data.filter(row => Object.keys(row).length > 0);
};
