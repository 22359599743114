import React from 'react';
import './ContentHeader.scss';
import { CustomElementProps } from '../../types/react-props-types';

interface ContentHeaderProps extends CustomElementProps {
  onClick?: (e: any) => void;
  cypressData?: string;
}

const ContentHeader = (props: ContentHeaderProps) => {
  const { id, className, children, onClick, cypressData } = props;
  return (
    <div id={id} className={`content-header ${className || ''}`} role="content-header" onClick={(e: any) => onClick && onClick(e)} data-cy={cypressData}>
      {children}
    </div>
  );
};

export default ContentHeader;
